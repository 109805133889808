import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    detail: {},
    recipient: {},
  },
  getters: {
    detail: state => state.shipment,
    recipient: state => state.recipient,
  },
  mutations: {
    setShipmentDetail(state, payload) {
      state.detail = payload
    },
    setShipmentRecipient(state, payload) {
      state.recipient = payload
    },
    resetShipmentRecipient(state) {
      state.recipient = null
    },
  },
  actions: {
    async getShipmentDetail({ commit }, id) {
      try {
        commit('setShipmentDetail', {})
        const data = await Vue.prototype.$api.shipmentDetail({ id })
        if (data.code === 200) {
          commit('setShipmentDetail', data.data)
          commit('setShipmentRecipient', data.data.recipient)
        } else {
          // 返回上一页
          window.history.go(-1)
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error)
      }
    },
    // TODO:跟下面的导出合并
    handleAdminExport(state, payload) {
      const params = new URLSearchParams(payload)
      const token = localStorage.getItem('accessToken')
      // eslint-disable-next-line
      fetch(`https://agentapi.ebaoguo.com/api/statistic_self_agent_by_yearmonth_excel?${params.toString()}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          const contentType = response.headers.get('Content-Type')
          return response.blob(contentType)
        })
        .then(blob => {
          const url = URL.createObjectURL(blob)
          const agentName = payload.self_agent_name.replace(/\s+/g, '_')
          const yearMonth = payload.year_month.replace(/-/g, '_')
          const filename = `${agentName}_${yearMonth}.xlsx`

          const link = document.createElement('a')
          link.href = url
          link.download = filename
          link.style.display = 'none'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          // 释放 URL 对象
          setTimeout(() => {
            URL.revokeObjectURL(url)
          }, 100)
        })
        .catch(error => {
          console.error('There has been a problem with your fetch operation:', error)
        })
    },
    handleExport(state, item) {
      const params = new URLSearchParams({
        start_time: item.week_start,
        end_time: item.week_end,
      })
      const token = localStorage.getItem('accessToken')
      // eslint-disable-next-line
      fetch(`https://agentapi.ebaoguo.com/api/obtain_agent_fees_excel?${params.toString()}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          const contentType = response.headers.get('Content-Type')
          return response.blob(contentType)
        })
        .then(blob => {
          const url = URL.createObjectURL(blob)
          const filename = 'agent_fees.xlsx'

          const link = document.createElement('a')
          link.href = url
          link.download = filename
          link.style.display = 'none'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          // 释放 URL 对象
          setTimeout(() => {
            URL.revokeObjectURL(url)
          }, 100)
        })
        .catch(error => {
          console.error('There has been a problem with your fetch operation:', error)
        })
    },
  },
}
